import React from "react";
import LabCard from "../cards/Campus/LabCard";
import img1 from "../img/dot1.jpg";
import img2 from "../img/dot2.jpg";

const Dot = () => {
  let demoDot = [
    {
      name: "Mrs. M. Jyothi rani.M.A.TELUGU,B.Ed  ,UGC-NET,AP-SET.",
      img: img1,
      id: 1,
    },
    {
      name: "Dr.K.Lavanya I.M.A Telugu, UGC-JRF, NET, TS-SET,  Ph.D (Telugu)",
      img: img2,
      id: 2,
    },
  ];

  return (
    <>
      <div className="px-4 py-16 mx-auto md:px-24 lg:px-8 lg:py-20">
        <div className="mb-10 md:mx-auto sm:text-center md:mb-12 border p-3 rounded-lg">
          <h2
            className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
            data-aos="fade-up"
          >
            <span className="relative inline-block">
              <h2 class="text-3xl p-4">
                <span className="relative text-3xl text-blue-700 ">
                  Department Of Telugu
                </span>
              </h2>
            </span>{" "}
          </h2>
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <ul class="text-base text-gray-700 md:text-lg" data-aos="fade-up">
                <li className="text-2xl p-4 font-bold text-justify">
                About the Department
                </li>
                <li className="text-justify">
                  Telugu is a primary language spoken in the state of Telangana.
                  The Department of Telugu has been providing language learning
                  skills for the students. This department is being run with two
                  faculty members.
                </li>
                <br />
                <li className="text-2xl font-bold text-justify">Objectives</li>
                <li className="text-justify">
                  ● It inculcates Social Values and morals in the students
                </li>
                <li className="text-justify">
                  ●It enables the students to develop interest towards this
                  language
                </li>
                <li className="text-justify">
                  ●It spread the greatness of Telugu Language, Culture, Art and
                  Tradition as state festivals
                </li>
                <li className="text-justify">
                  ● It brought out the beauty in the Spoken Language
                </li>
              </ul>
            </div>
            <div className="flex flex-row justify-center ">
              <ul
                class="text-base text-gray-700 md:text-lg text-justify"
                data-aos="fade-up"
              >
                <li className="text-2xl p-4 font-bold text-justify">
                  Programmes Offered
                </li>
                <li className="pl-5">● B.A (HEP )</li>
                <li className="pl-5">● B.A (HPE)</li>
                <li className="pl-5">● B.Com (General)</li>
                <li className="pl-5">● B.Com (Computer)</li>
                <li className="pl-5">● B.Com (Business Analytics) </li>
                <li className="pl-5">● B.Sc (BZC)</li>
                <li className="pl-5">● B.Sc (BBC)</li>
                <li className="pl-5">● B.Sc (MPC)</li>
                <li className="pl-5">● B.Sc (MPCs)</li>
                <li className="pl-5">● B.Sc (MSCs)</li>
                <li className="pl-5">● B.Sc ( Data Science)</li>
              </ul>
            </div>
          </div>
          <div>
            <ul
              class="text-base text-gray-700 md:text-lg mt-3 text-justify"
              data-aos="fade-up"
            >
              <li className="text-justify text-2xl font-bold">
                Courses Offered:
              </li>
              <li>● Sahithi Manjeera</li>
              <li>● Sahithi Kinnera</li>
            </ul>
          </div>
          <br />
          <p
            class="text-3xl text-gray-700 md:text-3xl font-bold mt-5"
            data-aos="fade-up"
          >
            Faculty
          </p>
          <br />
          {/* <span className="relative text-3xl text-blue-700 p-10">
            Mrs. M. Jyothi rani
          </span>
          <p class="m-10 text-base text-gray-700 md:text-lg" data-aos="fade-up">
            M.JYOTHI RANI is a faculty member in the Department of Telugu. She
            holds a Master's degree in Telugu from Kakatiya University,
            Qualified AP-SET and UGC-NET in the year 2013 and also B.Ed from
            Osmania University. She had a teaching experience of 10 years.She
            had 5 years experience as a teacher in secondary school at Vedhasri
            Naveena Patashala Tharallapally, warangal. Later worked as a Guest
            faculty at Mahatma Jyothiba Pule BC welfare Residential Jr. College,
            L. M. D colony Karimnagar for 1 year and contract lecturer at KGBV,
            Station Ganpur for 1 year and then as Guest faculty at Telangna
            Tribal welfare Residential Degree college, Janagoan for 3 years,
            before she joined this institution. She joined this institution on
            13-03-2019.
          </p> */}
          <div className="grid md:grid-cols-2 bg-bg rounded p-3">
            <div className="gap-3 flex flex-col justify-center mx-auto">
              <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
              M.JYOTHI RANI
              </h1>
              <p className="text-justify text-white p-3 md:p-7">
              M.JYOTHI RANI is a faculty member in the Department of Telugu. She
            holds a Master's degree in Telugu from Kakatiya University,
            Qualified AP-SET and UGC-NET in the year 2013 and also B.Ed from
            Osmania University. She had a teaching experience of 10 years.She
            had 5 years experience as a teacher in secondary school at Vedhasri
            Naveena Patashala Tharallapally, warangal. Later worked as a Guest
            faculty at Mahatma Jyothiba Pule BC welfare Residential Jr. College,
            L. M. D colony Karimnagar for 1 year and contract lecturer at KGBV,
            Station Ganpur for 1 year and then as Guest faculty at Telangna
            Tribal welfare Residential Degree college, Janagoan for 3 years,
            before she joined this institution. She joined this institution on
            13-03-2019.
              </p>
            </div>
            <div className="p-3 flex flex-row justify-center">
              <div className=" flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                <img src={img1} className="p-3 w-60 h-60 object-contain rounded-full outline outline-white" />
                <h1 className="text-wrap text-white">
                Mrs. M. Jyothi rani.M.A.TELUGU,B.Ed  ,UGC-NET,AP-SET.
                </h1>
              </div>
            </div>
          </div>
          <br />
          {/* <span className="relative text-3xl text-blue-700 ">
            Dr.K.Lavanya{" "}
          </span>
          <p class="m-10 text-base text-gray-700 md:text-lg" data-aos="fade-up">
            Dr. K. Lavanya is a Degree Lecturer in Telugu. She Qualified UGC-NET
            in 2015. She got UGC-JRF and NET in 2017. She Qualified TS-SET in
            2017. She has Completed Ph.D from University of Hyderabad in 2019.
            She has 3 years of Research Experience. She Published 3 research
            papers. She has Two years of Experience in Teaching. She has
            attended various National Seminars.
          </p> */}
          <div className="grid md:grid-cols-2 bg-bg p-3">
            <div className="gap-3 flex flex-col justify-center">
              <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
                Dr.K.Lavanya
              </h1>
              <p className="text-justify text-white p-3">
              Dr. K. Lavanya is a Degree Lecturer in Telugu. She Qualified UGC-NET
            in 2015. She got UGC-JRF and NET in 2017. She Qualified TS-SET in
            2017. She has Completed Ph.D from University of Hyderabad in 2019.
            She has 3 years of Research Experience. She Published 3 research
            papers. She has Two years of Experience in Teaching. She has
            attended various National Seminars.
              </p>
            </div>
            <div className="p-3 flex flex-row justify-center">
              <div className=" flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                <img src={img2} className="p-3 w-60 h-60 object-contain rounded-full outline outline-white" />
                <h1 className="text-wrap text-white">
                Dr.K.Lavanya I.M.A Telugu, UGC-JRF, NET, TS-SET,  Ph.D (Telugu)
                </h1>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col justify-evenly ">
          <div className="flex flex-wrap justify-around items-center p-2">
            {demoDot.map((Dot) => (
              <div
                key={Dot.id}
                className="mb-4 w-full flex justify-center md:w-1/3 px-2"
              >
                <LabCard name={Dot.name} img={Dot.img} />
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Dot;
