import React,{useRef} from 'react'
import { HalfPageBanner } from '../components/General/Half'
import n1 from '../assets/Nss/n1.jpg'
import n2 from '../assets/Nss/n2.jpg'
import n3 from '../assets/Nss/n3.jpg'


const Nss = () => {
  return (
    <>
      <HalfPageBanner name={"NSS Program"} />
      <div className='Nss'>
        <h1 className='font-bold text-center text-2xl p-2 m-2'>NSS Programs</h1>
        <div className="flex flex-wrap justify-center align-middle md:justify-center space-y-4 md:space-y-0 md:space-x-8">
          <img src={n1} alt="Image1" className="w-full md:w-1/4 transition-transform duration-300 lg:hover:scale-110" />
          <img src={n2} alt="Image2" className="w-full md:w-1/4 transition-transform duration-300 lg:hover:scale-110" />
          <img src={n3} alt="Image2" className="w-full md:w-1/4 transition-transform duration-300 lg:hover:scale-110" />
        </div>
        <p className='text-center font-light p-2 m-2'>  National Service3 Scheme(NSS) is youth programmes in association with state and Central Governments through Universities. There are 2 NSS Units in the College involving in Regular activities for 120 hours  and Special camp for 7 days in adopted village.</p>
        <p className='text-center font-light p-2 m-2'>The NSS (National Service Scheme) units at MJPTBCWRDC, Wargal, play a crucial role in fostering social responsibility and community engagement among our students. Our college hosts two active NSS units, each comprising 100 dedicated members who are committed to making a positive impact both on campus and in the surrounding communities.</p> <p className='text-center font-light p-2 m-2'>The NSS units regularly organize and participate in a variety of programs aimed at improving public health, promoting environmental sustainability, and enhancing social awareness. Key activities include health awareness programs, Clean and Green initiatives, and the observance of important national and international days. Additionally, our NSS volunteers play a pivotal role in maintaining discipline during college events.</p>
        <p className='text-center font-light p-2 m-2'>Beyond campus activities, our NSS members are encouraged to engage in external events and workshops, broadening their horizons and contributing to society at large. They also conduct surveys on critical issues like unemployment and education, helping to gather valuable data that can inform future initiatives.
          Through these diverse activities, the NSS units at <b className='font-bold'>MJPTBCWRDC</b>, Warangal, strive to develop a sense of civic duty and leadership in our students, preparing them to be responsible citizens and effective change-makers.
        </p>
      </div><hr />
    </>
  )
}

export default Nss