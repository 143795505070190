import React from "react";

import LabCard from "../cards/Campus/LabCard";

import img1 from "../img/chem1.jpg";
import img2 from "../img/chem2.png";
import img3 from "../img/chem3.png";

const Chem = () => {
  let demoChem = [
    {
      name: "Dr Radha Velchuri, M.Sc Chemistry, Ph.D Chemistry",
      img: img1,
      id: 1,
    },
    {
      name: "Dr.Janaki palla, M.sc chemistry, CSIR-JRF, NET, Ph.D (CHEMISTRY)",
      img: img2,
      id: 2,
    },
    {
      name: "M.Swathi, MSc.Chemistry, B.Ed pursuing",
      img: img3,
      id: 3,
    },
  ];

  return (
    <>
      <div className="px-4 py-16 mx-auto md:px-24 lg:px-8 lg:py-20 border">
        <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
          <h2
            className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
            data-aos="fade-up"
          >
            <span className="relative inline-block">
              <span className="relative text-3xl text-blue-700 ">
                Department Of Chemistry
              </span>
            </span>{" "}
          </h2>
          <p
            className="text-base text-gray-700 md:text-lg"
            data-aos="fade-up"
          ></p>
          <div className="grid md:grid-cols-2 gap-4 p-3">
            <ul
              className="text-base text-gray-700 md:text-lg"
              data-aos="fade-up"
            >
              <li className="text-3xl p-4 text-center font-bold">
                About the department
              </li>
              <li className="text-justify">
                There are two Laboratories well equipped with the necessary
                instruments/ infrastructure to perform experiments in
                Analytical, Inorganic, Organic and Physical Chemistry. All the
                graduation students are comfortably accommodated in these
                laboratories. The Department is well equipped with the necessary
                infrastructure and sophisticated instruments like Colorimetry,
                Conductometry, pH metry, Potentiometry, etc.
              </li>
              <br></br>
              <li className="text-justify font-bold text-2xl">Objectives</li>
              <li className="text-justify">
                • To propagate higher education in Chemistry. The quality of
                education would focus on concepts and their applications and
                modeling of different phenomenon in domains of physical,
                chemical and biological sciences.
              </li>
              <li className="text-justify">
                • To enhance interactions with National level Academia/Research
                Institutions to provide opportunities to students to carry out
                quality research and academics.{" "}
              </li>
              <li className="text-justify">
                • To provide wider job opportunities through academic and
                industry interactions.
              </li>
              <li className="text-justify">
                • To strengthen the Instrumentation Centre facility to start
                skill based courses and to cater to the needs of Academia and
                Industry.
              </li>
              <li className="text-justify">
                • To start courses in medicinal chemistry, cosmetic science, and
                address problems related to energy and environment.
              </li>
            </ul>

            <ul
              className="text-base text-gray-700 md:text-lg"
              data-aos="fade-up"
            >
              <li className="text-2xl font-bold">Courses Offered</li>
              <li>● BSc.MPC</li>
              <li>● BSc.BZC</li>
            </ul>
          </div>
          <br></br>
          <p class="text-3xl text-gray-700" data-aos="fade-up">
            Faculty
          </p>
          <br></br>
          {/* <span className="relative text-3xl text-blue-700 ">
            Dr Radha Velchuri
          </span>
          <p
            className="m-10 text-base text-gray-700 md:text-lg text-justify"
            data-aos="fade-up"
          >
            Dr. Radha Velchuri is a Degree Lecturer in Chemistry. She has been
            awarded Ph.D Chemistry from the Osmania University in the year 2012
            for the topic “Metathesis Synthesis and Spectral Characterization of
            Borates and Phosphates”. To her credit she had published 44 papers
            in Peer reviewed International and National Journals indexed in SCI
            and attended about 10 conferences in India. Her areas of research
            specialization are Synthesis of Nanomaterials and bulk materials,
            characterization, Photoluminescence, Photoactivity, etc.
          </p> */}
          <div className="bg-bg p-3 text-white">
            <div className="grid md:grid-cols-2 p-3 rounded">
              <div className="gap-3 flex flex-col justify-center">
                <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
                  Dr Radha Velchuri
                </h1>
                <p className="text-justify text-white p-3">
                  Dr. Radha Velchuri is a Degree Lecturer in Chemistry. She has
                  been awarded Ph.D Chemistry from the Osmania University in the
                  year 2012 for the topic “Metathesis Synthesis and Spectral
                  Characterization of Borates and Phosphates”. To her credit she
                  had published 44 papers in Peer reviewed International and
                  National Journals indexed in SCI and attended about 10
                  conferences in India. Her areas of research specialization are
                  Synthesis of Nanomaterials and bulk materials, characterization,
                  Photoluminescence, Photoactivity, etc.
                </p>
              </div>
              <div className="p-3 flex flex-row justify-center">
                <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                  <img src={img1} className="p-3 w-full h-full object-contain rounded-full outline outline-white" />
                  <h1 className="text-wrap text-white mt-2">Dr Radha Velchuri</h1>
                </div>
              </div>
            </div>
            <ul
              className="text-base text-white md:text-lg text-justify"
              data-aos="fade-up"
            >
              <li className="font-bold">HONORS AND AWARDS :</li>
              <li>
                ● Fast Track Young Scientist, DST (Department of Science &amp;
                Technology), New Delhi, 2014.
              </li>
              <li>
                ● Senior Research Fellow, CSIR (Council of Scientific and
                Industrial Research), New Delhi, Govt. of India, 2011.
              </li>
              <li>
                ● Received a Gold medal for standing first in M.Sc Physical
                Chemistry in 2006.
              </li>
            </ul>
            <br></br>
            <ul
              className="text-base text-white md:text-lg text-justify"
              data-aos="fade-up"
            >
              <li className="font-bold">RESEARCH EXPERIENCE:</li>
              <li>
                ● Gained experience in the synthesis of Borates and Phosphates by
                metathesis and sol-gel methods.
              </li>
              <li>
                ● Doping of Sn 2+ , Ag + and nitrogen in the above mentioned
                materials.
              </li>
              <li>
                ● Gained experience in the basic characterization techniques such
                as powder XRD, FT-IR,
              </li>
              <li>
                ● Worked as a Fast Track Young Scientist in a DST sponsored
                project entitled “Synthesis and photocatalytic activity of Bulk
                and nano sized metal titanates” under the supervision of Dr. N. R.
                Munirathnam, C-MET (Centre for Materials for Electronics
                Technology), Hyderabad
              </li>
              <li>
                ● Worked as a Research Associate in Osmania University in a DST
                Project under the supervision of Prof. M. Vithal, Department of
                Chemistry, Osmania University, Hyderabad.
              </li>
            </ul>
          </div>
          <br></br>
          {/* <span className="relative text-3xl text-blue-700 ">
            Dr.Janaki palla
          </span>
          <p
            className="m-10 text-base text-gray-700 md:text-lg"
            data-aos="fade-up"
          >
            Dr.Janaki Palla is a Degree Lecturer in Chemistry. She qualified
            CSIR-JRF and NET in 2010 and completed Ph.D. from Osmania University
            in 2017. She has 5 years of Research experience. Her areas of
            research “SYNTHESIS AND ANTIMICROBIAL EVALUATION OF NOVEL
            BENZOFURANS, 4-AZAINDOLE BASED HETEROCYCLIC COMPOUNDS AND
            CHALCONES”. She has published about nine papers in international and
            national journals.She has attended five conferences.
          </p> */}
          <div className="grid md:grid-cols-2 bg-bg p-4">
            <div className="gap-3 flex flex-col justify-center">
              <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
                Dr.Janaki palla
              </h1>
              <p className="text-justify p-3 text-white">
                Dr. Radha Velchuri is a Degree Lecturer in Chemistry. She has
                been awarded Ph.D Chemistry from the Osmania University in the
                year 2012 for the topic “Metathesis Synthesis and Spectral
                Characterization of Borates and Phosphates”. To her credit she
                had published 44 papers in Peer reviewed International and
                National Journals indexed in SCI and attended about 10
                conferences in India. Her areas of research specialization are
                Synthesis of Nanomaterials and bulk materials, characterization,
                Photoluminescence, Photoactivity, etc.
              </p>
            </div>
            <div className="p-3 flex flex-row justify-center">
              <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                <img src={img2} className="p-3 w-60 h-60 object-contain rounded-full" />
                <h1 className="text-wrap text-white">Dr.Janaki palla</h1>
              </div>
            </div>
          </div>
          <br></br>
          {/* <span className="relative text-3xl text-blue-700 ">M.Swathi</span>
          <p
            className="m-10 text-base text-gray-700 md:text-lg"
            data-aos="fade-up"
          >
            Swathi Mahankali is working as a Degree Lecturer in MJPTBCWRDC,
            Wargal Siddipet. She did Project work for the duration of 3 months
            which was a part of MSc. Chemistry 4 th semester on Project Title “A
            new validated RP HPLC method for the development of Cobicistat and
            Darunavir in bulk and pharmaceutical dosage forms” in AUROBINDO
            PHARMA LIMITED Unit-1. Attended the workshop on “ Advances in
            Chemical Reactor Technology” (1-day workshop) at IST, JNTUH.
          </p> */}
          <div className="grid md:grid-cols-2 bg-bg p-3">
            <div className="gap-3 flex flex-col justify-center">
              <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
                M.Swathi
              </h1>
              <p className="text-justify text-white p-3">
                Swathi Mahankali is working as a Degree Lecturer in MJPTBCWRDC,
                Wargal Siddipet. She did Project work for the duration of 3 months
                which was a part of MSc. Chemistry 4 th semester on Project Title “A
                new validated RP HPLC method for the development of Cobicistat and
                Darunavir in bulk and pharmaceutical dosage forms” in AUROBINDO
                PHARMA LIMITED Unit-1. Attended the workshop on “ Advances in
                Chemical Reactor Technology” (1-day workshop) at IST, JNTUH.
              </p>
            </div>
            <div className="p-3 flex flex-row justify-center">
              <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                <img src={img3} className="p-3 w-60 h-60 object-contain rounded-full" />
                <h1 className="text-wrap text-white">M.Swathi</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chem;
