import React from 'react';
import PrincipalCard from '../cards/About/PrincipalCard';
import img from './img.jpg';

let members = [
  {
    "name": "SRI ANUMULA REVANTH REDDY",
    "period": "The Hon’ble Chief Minister",
    "img": "https://lh7-us.googleusercontent.com/v4PIEGb0mGLzN825eEdfDmWXh5JrKfuZGGI9HqvqY-MjYw51slch_BRtEGT5RdFZ_ef_yQOGZ4qDXr7zdQ1x4GQSpxSMnO9HmQFSa8e11bCbesZ2ZvPfoXoaZrLcFfD-t0NTc8-qbFU3d5qCNtJs4cY=s800"
  },
  {
    "name": "SRI PONNAM PRABHAKAR",
    "period": "Hon'ble Backward Classes Welfare Minister Government of Telangana",
    "img": "https://lh7-us.googleusercontent.com/QedkMHLJiuLJxI8pG4Kv54IXvm0BxF5XoDmt9u9jsGv1oLDOGfQIIQTrsPgueEyBkgfllsi67KtNq8XbedEKzVUv10afdPsCA1Lue1fzvZYjCgDJ45qCCD30xRfsGMnJd7OmJbK5kIs5D_LTePV967s=s800"
  },
  {
    "name": "SRI BURRA VENKATESHAM",
    "period": "Principal Secretary to Government (BC Welfare) & Vice-Chairman MJPTBCWREI Society",
    "img": "https://lh7-us.googleusercontent.com/ZVIj6_yp90DAgnqSq9P3BH235soCjSE1p7WYYGQG-CtGC5xSntGFOwaWdVHdFU9Hhip6k-0e0RZPLm1BsOyeUFAGLSSLb3Y9BltRjQNpENTWo5odh57opTPOHRxToP-2DB-Dkc5bq7EGJi5q_s5Rbjo=s800"
  },
  {
    "name": "Mr. B. SAIDULU",
    "period": "Hon'ble Secretary Of (MJPTBCWREIS) Telangana",
    "img": img
  },
  {
    "name": "Dr. G.BHASKAR RAO",
    "period": "Principal",
    "img": "https://lh7-us.googleusercontent.com/4JPQi1Snp4X7nbQ25jfuWGAKD87Qe9fvDk0iorRHInpAcR7SrstFS5rO9zcHE8W_L_lRsfQPBAUw4d42S0_ipO_3zZ9rTlX6BnMHMQqw540c0v1EtGD_uDLAwiofx01a4sEusxGa5SZwnxqnr9UQQUQ=s800"
  },
  {
    "name": "Sri.P.GOVINDA RAO",
    "period": "Vice Principal",
    "img": "https://lh7-us.googleusercontent.com/mlQ6xfb9WAOKPUry1F9GplijMsuzbC56M8IsW6xNiD75djMwxLou7LyK5YEKccPgwJCLGDGmGrQuclUcnd99KrinRL3eNxGPl9dVtrkoabbAKtVIin9VTkVJqxYIxsRYQ1dG36V42l_whGwo42u7898=s800"
  },
];

const Gov_Body = () => {
  return (
    <div className="flex flex-col justify-center items-center pt-10">
      <div className="text-center text-blue-700 text-4xl font-bold mb-8" data-aos="fade-up">
        GOVERNING BODY
      </div>
      <div className='flex flex-wrap justify-center items-center'>
        {members.map((item, index) => (
          <div key={index} className="mb-4 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2">
            <PrincipalCard name={item.name} period={item.period} img={item.img} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Gov_Body;
