import React from 'react'

import LabCard from '../cards/Campus/LabCard'

import img1 from "../img/psd1.png"
import img2 from "../img/psd2.png"

const Psd = () => {

  let demoPsd = [
    {
      "name": "A.K.Chandana",
      "img": img1,
      "id": 1
    }, {
      "name": "T.S.Rajani",
      "img": img2,
      "id": 2
    },
  ]

  return (
    <>
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
          <h2
            className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
            data-aos="fade-up"
          >
            <span className="relative inline-block">
              <span className="relative text-3xl text-blue-700 ">Department Of Political Science</span>
            </span>{' '}
          </h2>
          <p className="text-base text-gray-700 md:text-lg" data-aos="fade-up">
          </p>
          <div className=''>
            <ul className="text-base text-gray-700 md:text-lg" data-aos="fade-up">
              <li className='text-3xl p-4 text-justify'>About the department:</li>
              <li className='text-justify'>The political science department for UG Course was established in 2015.  Political Science is a fast growing social science.   It is extremely necessary for every citizen to get acquainted with the political process of the country.  Since Indian Society has very marginal political development, particularly the women section of the society is lagging behind in this context.  So the college has opted Political Science as an optional subject with the intention that it will helpful for the women section of the society particularly minorities and poor section, who have very minimal exposure to the political process as they are totally ignorant of political life.
                Since then the department has been rendering its service for UG courses. The department offers instructions in English media.  Since its inception the department has been rendering remarkable service and witnessing great change in the political understanding and political behavior of the students. </li>
              <br></br>
              <li className='font-bold'>Objectives:</li>
              <li>• To produce  capable and responsible citizens of the country</li>
              <li>• To inculcate moral and civic virtues among the students </li>
              <li>• To provide spirit of research & creativity  among the students</li>
              <li>• To bring electoral literacy awareness among the students</li>
              <li>• To prepare the students for all competitive examinations</li>
            </ul>
            <br></br>
            <p class="text-3xl text-gray-700 font-bold" data-aos="fade-up">
              Faculty:
            </p>
            <br></br>
            {/* <span className="relative text-3xl text-blue-700 ">A.K.Chandana</span>
            <p className="m-10 text-base text-gray-700 md:text-lg" data-aos="fade-up">
              Is a senior faculty member in the Department of Political Science.  She holds a Master’s Degree in Political Science from Osmania University, passed TS State Eligibility Test in the year 2013 and also B.Ed from Osmania University.  She is presently pursuing Ph.D. from Osmania University.  She has experience of teaching Political Science for more than 19 years.  She had a teaching experience of 12 years at Sri Anantha Padmanabha Arts, Science & Commerce College, Vikarabad, taught Political Science to the Degree students. Later she served as a Degree Lecturer at Government Degree College for Women, Zaheerabad, Government Degree College, Malkajgiri, KRR Government Degree College, Kodad and at Tara Government Degree College (Autonomous), Sangareddy before she joined this Institution, she joined on 27-03-2019.
            </p>
            <br></br>
            <span className="relative text-3xl text-blue-700 ">T.S.Rajani  </span>
            <p className="m-10 text-base text-gray-700 md:text-lg" data-aos="fade-up">
              Is a faculty member in the Department of Political Science.  She holds a Master’s Degree in Political Science from Osmania University, passed UGC National Eligibility Test in the year 2009.  She has an experience of teaching Political Science for the past 2 years in this Institution, she joined on 08-04-2019.
            </p> */}
            <div className="md:grid md:grid-cols-2 bg-bg flex flex-col-reverse px-2">
              <div className="gap-3 flex flex-col justify-center">
                <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
                A.K.Chandana
                </h1>
                <p className="text-justify text-white p-3">
                Is a senior faculty member in the Department of Political Science.  She holds a Master’s Degree in Political Science from Osmania University, passed TS State Eligibility Test in the year 2013 and also B.Ed from Osmania University.  She is presently pursuing Ph.D. from Osmania University.  She has experience of teaching Political Science for more than 19 years.  She had a teaching experience of 12 years at Sri Anantha Padmanabha Arts, Science & Commerce College, Vikarabad, taught Political Science to the Degree students. Later she served as a Degree Lecturer at Government Degree College for Women, Zaheerabad, Government Degree College, Malkajgiri, KRR Government Degree College, Kodad and at Tara Government Degree College (Autonomous), Sangareddy before she joined this Institution, she joined on 27-03-2019.
                </p>
              </div>
              <div className="p-3 flex flex-row justify-center">
                <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                  <img src={img1} className="p-3 w-60 h-60 object-contain rounded-full" />
                  <h1 className="text-wrap text-white">         A.K.Chandana</h1>
                </div>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 bg-bg flex flex-col-reverse">
              <div className="gap-3 flex flex-col justify-center px-2">
                <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
                T.S.Rajani  
                </h1>
                <p className="text-justify text-white p-3">
                Is a faculty member in the Department of Political Science.  She holds a Master’s Degree in Political Science from Osmania University, passed UGC National Eligibility Test in the year 2009.  She has an experience of teaching Political Science for the past 2 years in this Institution, she joined on 08-04-2019.
                </p>
              </div>
              <div className="p-3 flex flex-row justify-center">
                <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                  <img src={img2} className="p-3 w-60 h-60 object-contain rounded-full" />
                  <h1 className="text-wrap text-white">T.S.Rajani  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Psd