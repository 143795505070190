import React from 'react'
import img2 from '../img/usha.jpeg';

const Bchem = () => {
  return (
    <>
      <div className="px-4 py-16 mx-auto md:px-24 lg:px-8 lg:py-20 border">
        <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
          <h2
            className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
            data-aos="fade-up"
          >
            <span className="relative inline-block">
              <span className="relative text-3xl text-blue-700 ">Department Of Biochemistry</span>
            </span>{' '}
          </h2>
          <p className="text-base text-gray-700 md:text-lg" data-aos="fade-up">
          </p>
          <div className='grid md:grid-cols-2 p-2'>
            <ul className="text-base text-gray-700 md:text-lg text-justify" data-aos="fade-up">
              <li className='text-3xl mb-10'>About the department</li>
              <li>The Department of Biochemistry was started in the year 2019 with one faculty and strength of 36 students. From then onwards it has slowly and steadily picked up and presently it has a strength of 74 students. The Biochemistry Laboratory is well equipped and creates the ambience required for attaining hands-on experience in gaining the experimental skills. The Department rests on a strong system having an application-oriented approach utilizing the infrastructure to learn techniques. The course is offered with Biotechnology and Chemistry, the two most important subjects vital to the understanding of Biochemistry.  The faculty endeavours to lay a strong foundation of concepts, in students’ minds. The Motto of the department is to see that every student is made aware of the latest emerging trends and passes out with an Analytical approach towards the subject. </li>
            </ul>
            <ul className="text-base text-gray-700 md:text-lg flex flex-col gap-4" data-aos="fade-up">
              <li className='text-3xl'>Programmes Offered:</li>
              <li>●	BSc-Biochemistry</li>
              <li>●	Biotechnology</li>
              <li>●	Chemistry(BBC)</li>
            </ul>
          </div>
          <br></br>
          <p class="text-3xl text-gray-700 font-bold" data-aos="fade-up">
            Faculty
          </p>

          <br></br>
          {/* <span className="relative text-3xl text-blue-700 ">Mrs. M.Usha</span>
          <p className="m-10 text-base text-gray-700 md:text-lg" data-aos="fade-up">
            Mrs. M.Usha is a Lecturer in the Department of Biochemistry. She holds a Masters  Degree with distinction from Osmania University and B.Ed from Nagarjuna University. She has qualified CSIR-UGC NET. She has 15 years of teaching experience at graduate and post graduate level. She has two papers published to her credit in UGC recognized National and indexed International Journals.  She is specialized in the areas of Molecular Biology, and  Biochemical Techniques. She has expertise in training students in  laboratory work. She has guided several projects for Postgraduate students.She is an evaluator for B.Sc and M.Sc Semester end examinations of Osmania University. She has presented papers in National and International level workshops and undergone training programmes by IIT’s. She also has to her credit organizing several National Symposia, workshops and outreach programmes. She served as Deputy Warden for the academic year 2019-20 at MJPTBC Residential Degree College for Women-Wargal.

          </p> */}
          <div className="md:grid md:grid-cols-2 bg-bg flex flex-col-reverse">
            <div className="gap-3 flex flex-col justify-center">
              <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
                Mrs. M.Usha
              </h1>
              <p className="text-justify text-white p-3">
                Mrs. M.Usha is a Lecturer in the Department of Biochemistry. She holds a Masters  Degree with distinction from Osmania University and B.Ed from Nagarjuna University. She has qualified CSIR-UGC NET. She has 15 years of teaching experience at graduate and post graduate level. She has two papers published to her credit in UGC recognized National and indexed International Journals.  She is specialized in the areas of Molecular Biology, and  Biochemical Techniques. She has expertise in training students in  laboratory work. She has guided several projects for Postgraduate students.She is an evaluator for B.Sc and M.Sc Semester end examinations of Osmania University. She has presented papers in National and International level workshops and undergone training programmes by IIT’s. She also has to her credit organizing several National Symposia, workshops and outreach programmes. She served as Deputy Warden for the academic year 2019-20 at MJPTBC Residential Degree College for Women-Wargal.</p>
            </div>
            <div className="p-3 flex flex-row justify-center">
              <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                <img src={img2} className="p-3 w-60 h-60 object-contain rounded-full outline outline-white" />
                <h1 className="text-wrap mt-4 text-white">
                  Mrs. M.Usha
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Bchem