import React from 'react'
import { HalfPageBanner } from '../components/General/Half'
import nsd1 from '../assets/Programs&Events/nsd1.jpg'
import nsd2 from '../assets/Programs&Events/nsd2.jpg'
import wd1 from '../assets/Programs&Events/wd1.jpg'
import wd2 from '../assets/Programs&Events/wd2.jpg'
import a1 from '../assets/Programs&Events/a1.jpg'
import a2 from '../assets/Programs&Events/a2.jpg'
import f1 from '../assets/Programs&Events/f1.jpg'
import f2 from '../assets/Programs&Events/f2.jpg'
import k1 from '../assets/Programs&Events/k1.jpg'
import k2 from '../assets/Programs&Events/k2.jpg'
const Programs = () => {
    return (
        <>
            <HalfPageBanner name={"Programs & Events"} />
            <div className='National Science Day' id='National'>
                <h1 className='font-bold text-center text-2xl p-2 m-2'>National Seminar Day</h1>
                <div className="flex flex-wrap justify-center align-middle md:justify-center space-y-4 md:space-y-0 md:space-x-8">
                    <img src={nsd1} alt="Image1" className="w-full md:w-1/3 transition-transform duration-300 lg:hover:scale-110"/>
                    <img src={nsd2} alt="Image2" className="w-full md:w-1/3 transition-transform duration-300 lg:hover:scale-110"/>
                </div>
                <p className='text-center font-light p-2 m-2'>Our college’s National Seminar Day brings together experts and students to discuss key topics in various fields. The event features keynote speeches, interactive sessions, and opportunities for networking, making it a highlight of our academic year.</p>
            </div><hr />
            <div className='International Womens Day' id='International'>
                <h1 className='font-bold text-center text-2xl p-2 m-2'>International Womens Day</h1>
                <div className="flex flex-wrap justify-center align-middle md:justify-center space-y-4 md:space-y-0 md:space-x-8">
                    <img src={wd1} alt="Image1" className="w-full md:w-1/3 transition-transform duration-300 lg:hover:scale-110" />
                    <img src={wd2} alt="Image2" className="w-full md:w-1/3 transition-transform duration-300 lg:hover:scale-110" />
                </div>
                <hr />
                <p className='text-center font-light p-2 m-2'>Our college proudly celebrates Women's Day to honor the achievements and contributions of women in all walks of life. The event includes inspiring speeches, cultural performances, and interactive sessions, highlighting the importance of gender equality and empowerment.
                </p>
            </div>
            <div className='Annual Day' id='Annual'>
                <h1 className='font-bold text-center text-2xl p-2 m-2'>Annual Day Celebrations</h1>
                <div className="flex flex-wrap justify-center align-middle md:justify-center space-y-4 md:space-y-0 md:space-x-8">
                    <img src={a1} alt="Image1" className="w-full md:w-1/3 transition-transform duration-300 lg:hover:scale-110" />
                    <img src={a2} alt="Image2" className="w-full md:w-1/3 transition-transform duration-300 lg:hover:scale-110" />
                </div>
                <hr />
                <p className='text-center font-light p-2 m-2'>Our college's Annual Day is a vibrant celebration of our students' talents and achievements. The event features a variety of performances, including dance, music, and drama, along with award ceremonies recognizing academic and extracurricular excellence. It's a day of joy, unity, and pride for the entire college community.
                </p>
            </div>
            
            <div id='Freshers' className='freshers Day'>
                <p className='font-bold text-center text-2xl p-2 m-2'>Freshers Day Celebrations</p>
                <div className="flex flex-wrap justify-center align-middle md:justify-center space-y-4 md:space-y-0 md:space-x-8">
                    <img src={f2} alt="Image1" className="w-full md:w-1/3 transition-transform duration-300 lg:hover:scale-110" />
                    <img src={f1} alt="Image2" className="w-full md:w-1/3 transition-transform duration-300 lg:hover:scale-110" />
                </div>
                <hr />
                <p className='text-center font-light p-2 m-2'>Freshers' Day is a special occasion where our college welcomes the newest members of our academic family. The event is filled with fun activities, engaging performances, and ice-breaking sessions, creating a warm and welcoming atmosphere for the freshers. It's a memorable start to their journey with us.
                </p>
            </div>
            <div className='Karnataka' id='karnatak'>
                <h1 className='font-bold text-center text-2xl p-2'>Karnataka Team Visit</h1>
                <div className="flex flex-wrap justify-center align-middle md:justify-center space-y-4 md:space-y-0 md:space-x-8">
                    <img src={k1} alt="Image1" className="w-full md:w-1/3 transition-transform duration-300 lg:hover:scale-110" />
                    <img src={k2} alt="Image2" className="w-full md:w-1/3 transition-transform duration-300 lg:hover:scale-110" />
                </div>
                <hr />
                <p className='text-center font-light p-2 m-2'>Our college recently hosted a visit from the esteemed Karnataka team. The visit included insightful discussions, collaborative sessions, and a tour of our campus facilities. It was an excellent opportunity for knowledge exchange and strengthening ties with industry professionals and academic experts from Karnataka.
                </p>
            </div>
        </>
    )
}

export default Programs