import React from "react";
import LabCard from "../cards/Campus/LabCard";
import img1 from "../img/bt1.jpg";

const Biotech = () => {
  let demoBiotech = [
    {
      name: "Mrs.G.Srujana ",
      img: img1,
      id: 1,
    },
  ];

  return (
    <>
      <div className="px-4 py-16 mx-auto md:px-24 lg:px-8 lg:py-20">
        <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
          <h2
            className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
            data-aos="fade-up"
          >
            <span className="relative inline-block">
              <span className="relative text-3xl text-blue-700">
                Department Of Biotechnology
              </span>
            </span>{" "}
          </h2>
          <p
            className="text-base text-gray-700 md:text-lg"
            data-aos="fade-up"
          ></p>
          <div className="grid md:grid-cols-2 p-3 border mt-4 gap-4">
          <ul className="text-base text-gray-700 md:text-lg" data-aos="fade-up">
            <li className="text-3xl p-4 font-bold">About the department</li>
            <li className="text-justify">
              The Department of Biotechnology was organized in 2019 ,when the
              course Biotechnology, Biochemistry,Chemistry (BBC) was
              introduced.Biotechnology department envisions the skill
              development of students that encourages and trains them to apply
              curricular knowledge in Research / Industrial
              applications,appealing to the International community.The
              Department works in collaboration with departments of Biochemistry
              and Chemistry to provide wide spectrum knowledge to the students
              pursuing BtBcC (Biotechnology, Biochemistry,Chemistry) course with
              emphasis on practical experience. The department strives to
              inculcate moral and ethical values enabling the personality
              development of students
            </li>
            <br></br>
          </ul>
          <ul
            className="text-base text-gray-700 md:text-lg text-justify pl-10"
            data-aos="fade-up"
          >
            <li className="text-2xl font-bold text-center mb-5">Courses Offered</li>
            <li>● Cell Biology and Genetics</li>
            <li>● Biological Molecules and Microbiology</li>
            <li>● Molecular biology and r DNA technology</li>
            <li>● Bioinformatics and Biostatistics</li>
            <li>● Plant Biotechnology</li>
            <li>● IPR,Biosafety and Entrepreneurship</li>
            <li>● Animal Biotechnology</li>
            <li>● Immunological Techniques</li>
            <li>● Molecular markers in Plant breeding</li>
            <li>● Basics in Biotechnology</li>
          </ul>
          </div>
          <br></br>
          <br></br>
          <p class="text-3xl text-gray-700 font-bold" data-aos="fade-up">
            Faculty
          </p>
          <br></br>
          {/* <span className="relative text-3xl text-blue-700 ">
            Mrs.G.Srujana{" "}
          </span>
          <p
            className="text-base m-10  text-gray-700 md:text-lg"
            data-aos="fade-up"
          >
            BSc Biotechnology (2006-2009) at Ideal Degree College, OU MSc
            Biotechnology (2009-2011) at Gitam University, Visakhapatnam.
            Qualified CSIR-NET-LS in 2012 Research experience: Worked as
            Project- JRF (2013-2015) at IICT on "miRNA signalling and drug
            targeting in cancers". Worked as Project- -JRF (2011-2013) at CDFD
            on "Structural Characterization of Acyl Co-A binding proteins (ACBP)
            of Plasmodium falciparum. Published 3 papers in peer reviewed
            journals Was a team member of Poster Presentation at an
            International conference
          </p> */}
          <br></br>
        </div>
        <div className="md:grid md:grid-cols-2 bg-bg flex flex-col-reverse">
          <div className="gap-3 flex flex-col justify-center">
            <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
            Mrs.G.Srujana
            </h1>
            <p className="text-justify text-white p-3">
            BSc Biotechnology (2006-2009) at Ideal Degree College, OU MSc
            Biotechnology (2009-2011) at Gitam University, Visakhapatnam.
            Qualified CSIR-NET-LS in 2012 Research experience: Worked as
            Project- JRF (2013-2015) at IICT on "miRNA signalling and drug
            targeting in cancers". Worked as Project- -JRF (2011-2013) at CDFD
            on "Structural Characterization of Acyl Co-A binding proteins (ACBP)
            of Plasmodium falciparum. Published 3 papers in peer reviewed
            journals Was a team member of Poster Presentation at an
            International conference
            </p>
          </div>
          <div className="p-3 flex flex-row justify-center">
            <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
              <img src={img1} className="p-3 w-60 h-60 object-contain rounded-full outline outline-white" />
              <h1 className="text-wrap text-white mt-4">Mrs.G.Srujana</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Biotech;
