import React from 'react'

import LabCard from '../cards/Campus/LabCard'

import img1 from "../img/m1.jpg"
import img2 from "../img/m2.jpg"

const Language = () => {

  let demoLanguage = [
    {
      "name": "K.Bhagya lakshmi M.Sc,B.Ed,APSET,(Ph.D)",
      "img": img1,
      "id": 1
    }, {
      "name": "A.Shalini  MSc, B.Ed, AP-SET.",
      "img": img2,
      "id": 2
    },
  ]

  return (
    <>
      <div className="px-4 py-16 mx-auto md:px-24 lg:px-8 lg:py-20">
        <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
          <h2
            className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
            data-aos="fade-up"
          >
            <span className="relative inline-block">
              <span className="relative text-3xl text-blue-700 ">Department Of Mathematics</span>
            </span>{' '}
          </h2>
          <p className="text-base text-gray-700 md:text-lg" data-aos="fade-up">
          </p>
          <div className='grid md:grid-cols-2 md:gap-20'>
            <ul className="text-base text-gray-700 md:text-lg text-justify" data-aos="fade-up">
              <li className='text-3xl mb-7'>About the department:</li>
              <li className='text-justify'>
                The department has well qualified young and dynamic faculty members. We believe our students and their performance only can act as perfect testimonials. So our main objective is to impart quality education to our students with best available facilities and devoted students sharing the enthusiasm for mathematics.
              </li>
              <br></br>
              <li>Objectives:</li>
              <li>● have developed problem solving skills.</li>
              <li>● have covered the core topics of advanced mathematics which the department considers appropriate to their degree programme.</li>
              <li>● have learned to apply critical and analytical reasoning and to present logical and concise arguments.</li>
              <li>● be able to understand and present sophisticated mathematical arguments and rigorous proofs.</li>
              <li>● be able to comprehend high levels of abstraction in the study of pure mathematics.</li>
              <li>● have had the opportunity to enhance their investigative, communication and presentation skills.</li>
              <li>● have to apply mathematical concepts and theorems to new situations. </li>
            </ul>

            <ul className="text-base text-gray-700 md:text-lg text-justify flex flex-col gap-3" data-aos="fade-up">
              <li className='text-3xl'>Programmes Offered:</li>
              <li>● Bsc  MPC ( maths, physics, chemistry)</li>
              <li>● Bsc  MPCs ( maths, physics, computer science)</li>
              <li>●	Bsc  MSCs ( maths, statistics, computer science)</li>
              <li>●	Bsc  MSDs ( maths, statistics, data science) </li>
            </ul>
          </div>
          <br></br>
          <ul className="text-base text-gray-700 md:text-lg text-justify" data-aos="fade-up">
            <li className='font-bold text-xl'>Courses Offered:</li>
            <li>●	Differential and integral calculus</li>
            <li>● Differential equations</li>
            <li>● Real Analysis </li>
            <li>● Algebra</li>
            <li>● Linear algebra</li>
            <li>● Numerical analysis</li>
            <li>● Integral transforms</li>
            <li>● Analytical solid geometry</li>
            <li>● Theory of equations</li>
            <li>● Logic & sets</li>
            <li>● Number theory</li>
            <li>● Vector calculus </li>
            <li>● Basic mathematics</li>
            <li>● Mathematics for economics & finance</li>
            <li>● Mathematical modelling. </li>
          </ul>
          <br></br>
          <br></br>
          <p class="text-3xl text-gray-700 font-bold" data-aos="fade-up">
            Faculty
          </p>
          <br></br>
          {/* <span className="relative text-3xl text-blue-700 "></span>
          <p className="text-base m-10 text-gray-700 md:text-lg" data-aos="fade-up">
            She is a faculty member in the Department of mathematics. She holds Master's Degree in mathematics from Osmania University since 2005, passed AP State Eligibility Test in the year 2013 and also B.Ed from Osmania University. She has 16 years of teaching experience. She worked as P.G college lecturer in Dhramavant P.G college for 3 years and as Asst. Professor in Vignan bharathi institute of technology and Nalla narsimha reddy group of institutions for 10 years later she joined Mahatma jyothi baphule residential institutions in 2018 as Trained Graduate Teacher for one year.She joined MJPTBCWRDC on 09.03.2019.        </p>
          <br></br>
          <span className="relative text-3xl text-blue-700 ">A.Shalini  </span>
          <p className="text-base m-10 text-gray-700 md:text-lg" data-aos="fade-up">

            She Is a faculty member in the Department of mathematics. She holds a Master's Degree in mathematics from Osmania University, passed AP State Eligibility Test in the year 2012 and also B.Ed from Osmania University. She had a teaching experience of 8 years. She worked as Contract Resident Teacher in Telangana Social Welfare Residential School at Chitkul, Sangareddy and Ranjol, Zaheerabad. Later worked as Post Graduate Teacher in Telangana Model School & Jr. College, Mattampally, Suryapet, before she joined this institution. She joined this institution on 13-03-2019.
          </p> */}

          <div className="md:grid md:grid-cols-2 bg-bg flex flex-col-reverse">
            <div className="gap-3 flex flex-col justify-center">
              <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
                K.Bhagya lakshmi
              </h1>
              <p className="text-justify text-white p-3">
              She is a faculty member in the Department of mathematics. She holds Master's Degree in mathematics from Osmania University since 2005, passed AP State Eligibility Test in the year 2013 and also B.Ed from Osmania University. She has 16 years of teaching experience. She worked as P.G college lecturer in Dhramavant P.G college for 3 years and as Asst. Professor in Vignan bharathi institute of technology and Nalla narsimha reddy group of institutions for 10 years later she joined Mahatma jyothi baphule residential institutions in 2018 as Trained Graduate Teacher for one year.She joined MJPTBCWRDC on 09.03.2019.
              </p>
            </div>
            <div className="p-3 flex flex-row justify-center">
              <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                <img src={img1} className="p-3 w-60 h-60 object-contain rounded-full outline outline-white" />
                <h1 className="text-wrap text-white mt-4">
                K.Bhagya lakshmi
                </h1>
              </div>
            </div>
          </div>

          <div className="md:grid md:grid-cols-2 bg-bg flex flex-col-reverse">
            <div className="gap-3 flex flex-col justify-center">
              <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
              A.Shalini 
              </h1>
              <p className="text-justify text-white p-3">
              She Is a faculty member in the Department of mathematics. She holds a Master's Degree in mathematics from Osmania University, passed AP State Eligibility Test in the year 2012 and also B.Ed from Osmania University. She had a teaching experience of 8 years. She worked as Contract Resident Teacher in Telangana Social Welfare Residential School at Chitkul, Sangareddy and Ranjol, Zaheerabad. Later worked as Post Graduate Teacher in Telangana Model School & Jr. College, Mattampally, Suryapet, before she joined this institution. She joined this institution on 13-03-2019.</p>
            </div>
            <div className="p-3 flex flex-row justify-center">
              <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                <img src={img2} className="p-3 w-60 h-60 object-contain rounded-full outline outline-white" />
                <h1 className="text-wrap text-white mt-4">
                A.Shalini 
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Language