import React from 'react';
import LabCard from '../cards/Campus/LabCard';
import img1 from "../img/z1.jpg";
import img2 from "../img/z2.png";

// Faculty profile component
const FacultyProfile = ({ name, details, imgSrc }) => (
  <div className="md:flex-row items-center mb-8 pl-4 md:grid md:grid-cols-2 bg-bg flex flex-col-reverse p-5">
    <div className="md:w-1/3 mb-4 md:mb-0 px-4">
      <LabCard name={name} img={imgSrc} />
    </div>
    <div className="md:w-2/3 text-justify">
      <span className="relative text-3xl text-blue-700 font-bold md:mb-0">{name}</span>
      <p className="text-base mt-4 text-white md:text-lg">{details}</p>
    </div>
  </div>
);

const Zoology = () => {
  let demoZoology = [
    {
      "name": "Mrs. Merugu Srilatha, M.Sc., (Zoology), B.Ed. and SET",
      "img": img1,
      "details": "Mrs. Merugu Srilatha is a Degree Lecturer in Zoology, Department of Zoology, and MJPTBCW Residential Degree College for Women-Wargal. She had qualified for the SET in 2014. She has completed her post graduation degree in Zoology from Osmania University and also completed B.Ed. from Osmania University. She has overall 8 years experience of teaching in Zoology at the undergraduate level. She had teaching experience of one year in Mahatma Jothiba Phule Telangana Backward Classes Welfare Residential School, Doulthabad. She has attended four National Seminars and Conferences"
    },
    {
      "name": "Mrs. Pratibha Biswas, M. NoSc., (Animal sciences), B.Ed. and CSIR-UGC NET",
      "img": img2,
      "details": "Mrs. Pratibha Biswas is a Degree Lecturer in Zoology, Department of Zoology, and MJPTBCW Residential Degree College for Women-Wargal. She had qualified for the CSIR-UGC NET in 2013. She has qualified GATE (Graduate Aptitude Test in Engineering2012) conducted by IISC, Bangalore. She was awarded with University SC/ST Medal as secured first rank among the SC/ST students (2013). She has completed her post graduation degree in Animal Sciences from University Of Hyderabad and also completed B.Ed. from Osmania University. She has overall 3 years experience of teaching in Zoology at the undergraduate level. She has teaching experience of 2 years in Telangana residential school & junior college (Boys), madnoor as post-graduate teacher (PGT). She has attended the national symposium on “biology of infection, immunity and disease control in pathogen-Plant Interaction.”2011. And participated in the genomics workshop conducted by University of Hyderabad 2012."
    },
  ];

  return (
    <div className="px-4 py-16 mx-auto md:px-24 lg:px-8 lg:py-20">
      {/* Department Header */}
      <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
        <h2 className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto" data-aos="fade-up">
          <span className="relative inline-block">
            <span className="relative text-3xl text-blue-700 ">Department Of Zoology</span>
          </span>{' '}
        </h2>
        {/* Department Description */}
        <div className='grid md:grid-cols-2 gap-10 '>
        <ul className="text-base text-gray-700 md:text-lg" data-aos="fade-up">
          <li className='text-3xl p-4 font-bold capitalize'>About the department</li>
          <li className='text-justify'>The Department of Zoology was established in the year 2015. The faculty members from the Department of Zoology are highly qualified, knowledgeable, experienced, dedicated and skilled. We are motivated to impart best teaching methodology to emphasise on basic and applied fields of zoology aided with demo, models, videos, wet laboratory having equipment such as, Compound Microscopes, Water Bath, Dissecting Microscope etc. The academy is structured to make learning beyond the classroom and also arranges various Seminars, Guest lectures, Field visits and Outreach programs.</li>
        </ul>
        <ul className="text-base text-gray-700 md:text-lg" data-aos="fade-up">
        <li className='text-justify text-2xl font-bold'>Objectives</li>
          <li className='text-justify'>● To provide students with knowledge of fundamental principles in zoology that will provide a foundation for their later advanced course in more specific biological subjects.</li>
          <li className='text-justify'>● To make students familiar with animal classification schemes and other applied courses as well as developing an understanding of and ability to apply basic zoological principles</li>
          <li className='text-justify'>● To integrate the laboratory and lecture sections of the course and directed toward teaching students both in the classroom and on the field.</li>
          <li className='text-justify'>● To provide quality education offering skill based programs and motivate the students   for self-employment in applied branches of Zoology.</li>
          <li className='text-justify'>● To inculcate the value based education and entrepreneurial skills among the students.</li>
          <li className='text-justify'>● To create awareness on environmental issues through various activities. </li>
        </ul>
        </div>
        <br />
        {/* Faculty Profiles */}
        <div className="mb-16 mt-10">
          <p className="text-3xl text-gray-700 font-bold" data-aos="fade-up">Faculty</p>
          {demoZoology.map((faculty) => (
            <FacultyProfile key={faculty.name} name={faculty.name} details={faculty.details} imgSrc={faculty.img} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Zoology;
