import React from 'react'
import Com from '../components/Departments/Com'

const Commerce = () => {
  return (
    <div>
      <Com/>
    </div>
  )
}

export default Commerce