import React from "react";
import LabCard from "../cards/Campus/LabCard";
import img1 from "../img/bot1.png";
import img2 from "../img/bot2.png";

const Botany = () => {
  let demoBotany = [
    {
      name: "Dr. B. SONI VEENA",
      img: img1,
      id: 1,
    },
    {
      name: "Mrs. CH. RATNAKALA",
      img: img2,
      id: 2,
    },
  ];

  return (
    <>
      <div className="px-4 py-16 mx-auto md:px-24 lg:px-8 lg:py-20 border">
        <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
          <h2
            className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
            data-aos="fade-up"
          >
            <span className="relative inline-block">
              <span className="relative text-3xl text-blue-700 ">
                Department Of Botany
              </span>
            </span>{" "}
          </h2>
          <p
            className="text-base text-gray-700 md:text-lg"
            data-aos="fade-up"
          ></p>
          <div className="grid md:grid-cols-2">
            <ul
              className="text-base text-gray-700 md:text-lg"
              data-aos="fade-up"
            >
              <li className="text-3xl p-4 font-bold">About the department</li>
              <li className="text-justify">
                The department is led by well qualified and committed faculty.
                The team of this department are always engaged in developing the
                optimal teaching and learning technique to deal with and inspire
                the students, during a healthy and friendly atmosphere,
                consistent with their learning capacity. The primary and
                foremost precedence of the Department of Botany is the overall
                development of each and every student of the college.
              </li>
              <br></br>
              <li className="font-bold text2-xl">Objectives</li>
              <li className="text-justify">
                • To achieve academic excellence in basic and applied aspects of
                plant sciences.
              </li>
              <li className="text-justify">
                • To understand the impact of plant diversity in the
                environment.
              </li>
              <li className="text-justify">
                • To inculcate scientific awareness towards protecting diversity
                and Eco-environmental management of plant resources for
                sustainable development.
              </li>
              <li className="text-justify">• To gain knowledge of plant breeding techniques.</li>
              <li className="text-justify">
                • To motivate the students to undertake PG studies in plant
                sciences
              </li>
            </ul>
            <ul
              className="text-base text-gray-700 md:text-lg"
              data-aos="fade-up"
            >
              <li className="text-3xl">Programmes Offered</li>
              <li>● BSc. Bachelor of Science (B.Z.C) </li>
            </ul>
          </div>
          <br></br>
          <p class="text-3xl text-gray-700" data-aos="fade-up">
            Faculty
          </p>
          <br></br>
          {/* <span className="relative text-3xl text-blue-700 ">
            Dr. B. SONI VEENA
          </span>
          <p
            className="m-10 text-base text-gray-700 md:text-lg"
            data-aos="fade-up"
          >
            Dr. B, Soni Veena is a Degree Lecturer in Botany, Department of
            Botany, MJPTBCW Residential Degree College for Women-Wargal. She has
            received B.Sc., B.Ed. and M.Sc. from Osmania University, Hyderabad.
            She had qualified for the SET in 2014 for Botany and pursued Ph.D.
            from the Department of Botany, Osmania University for the topic
            “Isolation and phytochemical analysis of bio-active compounds from
            some endemic plants”. Till now she has published about 8 research
            papers in reputed National and International journals. She has
            attended four National Seminars and Conferences. She has overall
            experience of teaching in Botany for 4 years at the undergraduate
            level. Dr. B. S. Veena organized One day Workshop on “Plant
            Biotechnology in Human Welfare” and plantation of herbal garden at
            MJPTBC Residential Degree College for Women-Wargal. She served as
            Deputy Warden for the academic year 2020-21 at MJPTBC Residential
            Degree College for Women-Wargal.
          </p> */}
          <div className="md:grid md:grid-cols-2 bg-bg flex flex-col-reverse">
            <div className="gap-3 flex flex-col justify-center">
              <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
              Dr. B. SONI VEENA
              </h1>
              <p className="text-justify text-white p-3">
              Dr. B, Soni Veena is a Degree Lecturer in Botany, Department of
            Botany, MJPTBCW Residential Degree College for Women-Wargal. She has
            received B.Sc., B.Ed. and M.Sc. from Osmania University, Hyderabad.
            She had qualified for the SET in 2014 for Botany and pursued Ph.D.
            from the Department of Botany, Osmania University for the topic
            “Isolation and phytochemical analysis of bio-active compounds from
            some endemic plants”. Till now she has published about 8 research
            papers in reputed National and International journals. She has
            attended four National Seminars and Conferences. She has overall
            experience of teaching in Botany for 4 years at the undergraduate
            level. Dr. B. S. Veena organized One day Workshop on “Plant
            Biotechnology in Human Welfare” and plantation of herbal garden at
            MJPTBC Residential Degree College for Women-Wargal. She served as
            Deputy Warden for the academic year 2020-21 at MJPTBC Residential
            Degree College for Women-Wargal.
              </p>
            </div>
            <div className="p-3 flex flex-row justify-center">
              <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                <img src={img1} className="p-3 w-60 h-60 object-contain rounded-full outline outline-white" />
                <h1 className="text-wrap text-white mt-5">
                Dr. B. SONI VEENA
                </h1>
              </div>
            </div>
          </div>
          <br></br>
          {/* <span className="relative text-3xl text-blue-700 ">
            Mrs. CH. RATNAKALA
          </span>
          <p
            className="m-10 text-base text-gray-700 md:text-lg"
            data-aos="fade-up"
          >
            Mrs. CH. Ratnakala is a faculty member in the Department of Botany.
            She Holds Master's degree in Botany and Bachelor of Education from
            Osmania University. She has five years of teaching experience. She
            has attended National seminars. After completing graduation, student
            can go for higher courses like M.Sc. (Botany),
            M.Sc.(Bio-technology), M.Sc.(Bio-Chemistry), M.Sc.(Micro Biology)
            and M.Sc.(Genetics). Amongst the careers available to a person who
            enjoys outdoors are positions as an ecologist, taxonomist,
            conservationist, and forester. Person with mathematical background
            might find biophysics, genetics, and developmental biology to be
            exciting fields. Someone with an interest in chemistry might become
            a plant physiologist, plant biochemist, molecular biologist or chemo
            taxonomist. Student can also choose biotechnology, microbiology,
            mycology and phycology. Student can also option for ornamental
            horticulture and landscape design. Student can obtain specialization
            in plant breeding, plant pathology, agronomy and horticulture.
            Teaching botany is challenging and rewarding career. Some botanist
            work in marketing or administration of plant related industries such
            as pharmaceutical companies, seed companies, biotechnology firms,
            scientific publishers and biological supply houses. Other plant
            biologists work in museums, herbaria and botanical gardens. Some
            with additional training become scientific writers, computer
            programmers and botanical illustrators.
          </p> */}
          <div className="md:grid md:grid-cols-2 bg-bg flex flex-col-reverse">
            <div className="gap-3 flex flex-col justify-center">
              <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
              Mrs. CH. RATNAKALA
              </h1>
              <p className="text-justify text-white p-3">
              Mrs. CH. Ratnakala is a faculty member in the Department of Botany.
            She Holds Master's degree in Botany and Bachelor of Education from
            Osmania University. She has five years of teaching experience. She
            has attended National seminars. After completing graduation, student
            can go for higher courses like M.Sc. (Botany),
            M.Sc.(Bio-technology), M.Sc.(Bio-Chemistry), M.Sc.(Micro Biology)
            and M.Sc.(Genetics). Amongst the careers available to a person who
            enjoys outdoors are positions as an ecologist, taxonomist,
            conservationist, and forester. Person with mathematical background
            might find biophysics, genetics, and developmental biology to be
            exciting fields. Someone with an interest in chemistry might become
            a plant physiologist, plant biochemist, molecular biologist or chemo
            taxonomist. Student can also choose biotechnology, microbiology,
            mycology and phycology. Student can also option for ornamental
            horticulture and landscape design. Student can obtain specialization
            in plant breeding, plant pathology, agronomy and horticulture.
            Teaching botany is challenging and rewarding career. Some botanist
            work in marketing or administration of plant related industries such
            as pharmaceutical companies, seed companies, biotechnology firms,
            scientific publishers and biological supply houses. Other plant
            biologists work in museums, herbaria and botanical gardens. Some
            with additional training become scientific writers, computer
            programmers and botanical illustrators.
              </p>
            </div>
            <div className="p-3 flex flex-row justify-center">
              <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                <img src={img2} className="p-3 w-60 h-60 object-contain rounded-full outline outline-white" />
                <h1 className="text-wrap text-white mt-5">
                Mrs. CH. RATNAKALA  
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Botany;
