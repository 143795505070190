import React from 'react'

import LabCard from '../cards/Campus/LabCard'

import img1 from "../img/stat1.png"
import img2 from "../img/stat2.jpg"

const Stats = () => {

  let demoStats = [
    {
      "name": "Ms. L.Saritha",
      "img": img1,
      "id": 1
    }, {
      "name": "Ms. M. PrasannaKumari",
      "img": img2,
      "id": 2
    },
  ]

  return (
    <>
      <div className="px-4 py-16 mx-auto md:px-24 lg:px-8 lg:py-20">
        <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
          <h2
            className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
            data-aos="fade-up"
          >
            <span className="relative inline-block">
              <span className="relative text-3xl text-blue-700 ">Department Of Statistics</span>
            </span>{' '}
          </h2>
          <p className="text-base text-gray-700 md:text-lg" data-aos="fade-up">
          </p>
          <div className='grid md:grid-cols-2 md:gap-20'>
            <ul className="text-base text-gray-700 md:text-lg" data-aos="fade-up">
              <li className='text-3xl mb-7 font-bold'>About the department</li>
              <li className='text-justify'>The Department of statistics, in MJPTBCWRDC college was established in 2015.Statistics is offered as the main subject to the following students of program courses. The Department has a well-equipped computer lab where the students get the opportunity and space to connect theoretical knowledge with practical learning.</li>
              <br></br>
              <li>Objectives:</li>
              <li>●	Graduates will be skilled in the fundamental concept of statistics neccessary for the success in industry or higher education. </li>
              <li>●	Graduates will be prepared to pursue career choice in different field like statistical quality control, Bio-statistics, samplling theory,life testing and industrial statistics among many others.</li>
            </ul>

            <ul className="text-base text-gray-700 md:text-lg flex flex-col items-center text-justify gap-5" data-aos="fade-up">
              <li className='text-3xl text-justify'>Programmes Offered:</li>
              <li className='text-justify'>●	B.Sc M.S.Cs (maths, Statistics, computer science) </li>
              <li className='text-justify'>●	B.Sc M.S.Ds ( maths, Statistics,Data science) </li>
              <li className='text-justify'>●	B. Com ( General, Computer applications & Business analytics) </li>
            </ul>
          </div>
          <br></br>
          <ul className="text-gray-700 text-justify mt-10" data-aos="fade-up">
            <li className='text-2xl font-bold'>Courses Offered:</li>
            <li>●	Descriptive statistics and probability </li>
            <li>●	probability Distributions</li>
            <li>●	Statistical methods and Theory of estimation </li>
            <li>●	Statistical Inference </li>
            <li>●	Sampling Theory, Time series, Index numbers & Demand Analysis. </li>
            <li>●	Statistical Quality Control and Reliability </li>
            <li>●	Design of experiments, Vital statistics, Official Statistics and Business forecasting. </li>
            <li>●	Operation Research.</li>
            <li>●	Business statistics - I</li>
            <li>●	Business statistics - II</li>
            <li>●	Data collection, presentation &Interpretation </li>
            <li>●	Data scalling Techniques &Report writing </li>
            <li>●	Big Data Analysis </li>
            <li>●	Statistical Techniques in Data mining </li>
            <li>●	Basic statistics - 1</li>
            <li>●	Basic statistics - 2</li>
          </ul>
          <br></br>
          <br></br>
          <p class="text-3xl text-gray-700 font-bold" data-aos="fade-up">
            Faculty
          </p>
          <br></br>
          {/* <span className="relative text-3xl text-blue-700 ">Ms. L.Saritha </span>
          <p className="text-base m-10 text-gray-700 md:text-lg" data-aos="fade-up">
            Is a faculty member in the Department of Statistics. She holds a Master's Degree in Statistics from University College of Science ,Osmania University. She has an experience of 4+ years  in teaching at undergraduate level in this Institution. She has qualified in TS-SET.        </p>
          <br></br>
          <span className="relative text-3xl text-blue-700 ">Ms. M. PrasannaKumari</span>
          <p className="text-base m-10 text-gray-700 md:text-lg" data-aos="fade-up">
            Is a faculty member in the Department of Statistics. She holds a Master's Degree in Applied Statistics from University College of Science, Osmania University.
          </p> */}
          <div className="md:grid md:grid-cols-2 bg-bg flex flex-col-reverse">
            <div className="gap-3 flex flex-col justify-center">
              <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
              Ms. L.Saritha
              </h1>
              <p className="text-justify text-white p-3">
              Is a faculty member in the Department of Statistics. She holds a Master's Degree in Statistics from University College of Science ,Osmania University. She has an experience of 4+ years  in teaching at undergraduate level in this Institution. She has qualified in TS-SET. </p>
            </div>
            <div className="p-3 flex flex-row justify-center">
              <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                <img src={img1} className="p-3 w-60 h-60 object-contain  rounded-full outline outline-white" />
                <h1 className="text-wrap mt-4 text-white">
                Ms. L.Saritha
                </h1>
              </div>
            </div>
          </div>
          <div className="md:grid md:grid-cols-2 bg-bg flex flex-col-reverse">
            <div className="gap-3 flex flex-col justify-center">
              <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
              Ms. M. PrasannaKumari
              </h1>
              <p className="text-justify text-white p-3">
              Is a faculty member in the Department of Statistics. She holds a Master's Degree in Applied Statistics from University College of Science, Osmania University.</p>
            </div>
            <div className="p-3 flex flex-row justify-center">
              <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                <img src={img2} className="p-3 w-60 h-60 object-contain  rounded-full outline outline-white" />
                <h1 className="text-wrap text-white mt-4">
                Ms. M. PrasannaKumari
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default Stats