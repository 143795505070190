import React from 'react'

import LabCard from '../cards/Campus/LabCard'

import img1 from "../img/phy1.jpg"
import img2 from "../img/phy2.jpg"

const Phy = () => {

  let demoPhy = [
    {
      "name": "G.P.VARJINI DEDEEPYA,M.SC.,B.ED,TS-SET",
      "img": img1,
      "id": 1
    }, {
      "name": "U.RAJITHA, MSc.,B.Ed,AP-SET.",
      "img": img2,
      "id": 2
    },
  ]

  return (
    <>
      <div className="px-4 py-16 mx-auto md:px-24 lg:px-8 lg:py-20">
        <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
          <h2
            className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
            data-aos="fade-up"
          >
            <span className="relative inline-block">
              <span className="relative text-3xl text-blue-700 ">Department Of Physics</span>
            </span>{' '}
          </h2>
          <p className="text-base text-gray-700 md:text-lg" data-aos="fade-up">
          </p>
          <div>
            <ul className="text-base text-gray-700 md:text-lg text-justify" data-aos="fade-up">
              <li className='text-3xl mb-7'>About the department</li>
              <li>The Department of Physics, established in the year 2015,offers undergraduate courses leading to the Bachelor of Science (B.Sc.)degree.In addition, project papers are done in the final semester (VI) by the department.
                Experienced faculty of the department is devoted to develop innovative and analytical approaches to physics learning.Topical seminars are arranged to create teaching-awareness among students

              </li>
            </ul>
          </div>
          <p class="text-3xl text-gray-700 font-bold" data-aos="fade-up">
            Faculty
          </p>
          <br></br>
          {/* <span className="relative text-3xl text-blue-700 ">G.P.VARJINI DEDEEPYA</span>
          <p className="m-10 text-base text-gray-700 md:text-lg" data-aos="fade-up">
            Is a faculty member in the Department of Physics.  She holds a Master’s Degree in Solid State Physics from Osmania University.Passed Telangana State Eligibility Test in the year 2014. She has an experience of 5years in teaching at Undergraduate level.
          </p>
          <br></br>
          <span className="relative text-3xl text-blue-700 ">U.RAJITHA</span>
          <p className="m-10 text-base text-gray-700 md:text-lg" data-aos="fade-up">
            Is  a faculty member in the Department of Physics.  She holds a Master’s Degree in Physics from Osmania University and a BEd degree from Mahatma Gandhi university.Passed  State Eligibility Test in the year 2012. She has an experience of 1 year as PGT  in TMREIS Society.She has been working in the institution since 2019.

          </p>

 */}
          <div className="md:grid md:grid-cols-2 bg-bg flex flex-col-reverse">
            <div className="gap-3 flex flex-col justify-center">
              <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
                G.P.VARJINI DEDEEPYA
              </h1>
              <p className="text-justify text-white p-3">
                Is a faculty member in the Department of Physics.  She holds a Master’s Degree in Solid State Physics from Osmania University.Passed Telangana State Eligibility Test in the year 2014. She has an experience of 5years in teaching at Undergraduate level.
              </p>
            </div>
            <div className="p-3 flex flex-row justify-center">
              <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                <img src={img1} className="p-3 w-60 h-60 object-contain rounded-full outline outline-white" />
                <h1 className="text-wrap text-white mt-4">
                  G.P.VARJINI DEDEEPYA
                </h1>
              </div>
            </div>
          </div>

        </div>
        <div className="md:grid md:grid-cols-2 bg-bg flex flex-col-reverse">
          <div className="gap-3 flex flex-col justify-center">
            <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
              U.RAJITHA
            </h1>
            <p className="text-justify text-white p-3">
              Is  a faculty member in the Department of Physics.  She holds a Master’s Degree in Physics from Osmania University and a BEd degree from Mahatma Gandhi university.Passed  State Eligibility Test in the year 2012. She has an experience of 1 year as PGT  in TMREIS Society.She has been working in the institution since 2019.
            </p>
          </div>
          <div className="p-3 flex flex-row justify-center">
            <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
              <img src={img2} className="p-3 w-60 h-60 object-contain rounded-full outline outline-white" />
              <h1 className="text-wrap text-white mt-4">
                U.RAJITHA

              </h1>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Phy