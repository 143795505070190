import React from "react";

import LabCard from "../cards/Campus/LabCard";

import img1 from "../img/cse1.jpg";
import img2 from "../img/cse2.jpg";

const Cs = () => {
  let demoCs = [
    {
      name: "Dr.S.Radharani",
      img: img1,
      id: 1,
    },
    {
      name: "Dr. K. Nikitha",
      img: img2,
      id: 2,
    },
  ];

  return (
    <>
      <div className="px-4 py-16 mx-auto md:px-24 lg:px-8 lg:py-20 border">
        <div className="mb-10 md:mx-auto sm:text-center md:mb-12">
          <h2
            className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
            data-aos="fade-up"
          >
            <span className="relative inline-block">
              <span className="relative text-3xl text-blue-700 ">
                Department Of Computer Science
              </span>
            </span>{" "}
          </h2>
          <p
            className="text-base text-gray-700 md:text-lg"
            data-aos="fade-up"
          ></p>
          <div className="grid md:grid-cols-2 p-3 gap-4">
            <ul className="text-base text-gray-700 md:text-lg" data-aos="fade-up">
              <li className="text-3xl p-4 text-justify">About the department:</li>
              <li className="text-justify">
                The Department of Computer Science is supported by dedicated, well
                qualified and experienced faculty. Department has a well
                established laboratory with 83 computers. The Department organizes
                various activities for enhancing students' computer skills.
                Students were encouraged in getting placement in reputed IT
                companies. Students interested in PG are getting admission in top
                Universities. Students are motivated to participate and organize
                extra-curricular activities.
              </li>
              <br></br>
              <li className="text-justify">Objectives:</li>
              <li className="text-justify">
                ● pursue advanced education and other creative works in technology
              </li>
              <li className="text-justify">● understand the fundamental principles of Computer Science</li>
              <li className="text-justify">
                ● Graduates will be successfully working on computing profession
              </li>
            </ul>
            <ul className="text-base text-gray-700 md:text-lg text-center pt-10" data-aos="fade-up">
              <li className="text-3xl">Programmes Offered:</li>
              <li>● B.COM(CA)</li>
              <li>● B.COM (BA)</li>
              <li>● BSc.MPCs</li>
              <li>● BSc.MSCs</li>
              <li>● BSc.MSDS </li>
            </ul>
          </div>
          <br></br>
          <p class="text-3xl text-gray-700 font-bold" data-aos="fade-up">
            Faculty
          </p>
          <br></br>
          {/* <span className="relative text-3xl text-blue-700 ">
            Dr.S.Radharani
          </span>
          <p
            className="m-10 text-base text-gray-700 md:text-lg"
            data-aos="fade-up"
          >
            M.C.A(Master of Computer Applications from OU, 1997), M. Tech.(
            2012), Ph. D. in the field of security in cloud computing. Worked as
            Assistant Professor in Department of CSE,University College of
            Engineering, Osmania University Hyderabad (2098-2019) 21 years. 24
            years of teaching and research experiences. Qualified in APSET.
            Published about 5 papers in peer reviewed International Journals
            indexed in SCI, Scopus and in International Conferences. Organized
            various conferences and workshops including an International
            Conferences sponsored by IEEE. Life Member of professional bodies
            IETE. Working in the field of Security issues in Cloud Computing .
          </p> */}
          <br></br>
          {/* <span className="relative text-3xl text-blue-700 ">
            Dr. K. Nikitha
          </span>
          <p
            className="m-10 text-base text-gray-700 md:text-lg"
            data-aos="fade-up"
          >
            B.Tech (Electronics and Communications Engg., JNTUH, 2003), M.
            Tech.(Information Technology, Andhra University, 2008), Ph.
            D.(Engg., GITAM University, Visakhapatnam, 2015) in the field of
            Wireless Sensor Networks. Worked as Associate Professor in
            Information Technology Department, MVSR Engineering College,
            Hyderabad (2017-19) two years. Formerly, as Assistant Professor in
            the Department of Computer Science and Engineering, GITAM University
            (2005-16). 16 years of teaching and research experiences. Qualified
            UGC-NET and TSET. Published about 15 papers in peer reviewed
            International Journals indexed in SCI, Scopus and about 10 in
            National and International Conferences. Organized various
            conferences and workshops including an International Conference on
            Wireless and Optical Communications Networks sponsored by IEEE. Life
            Member of professional bodies CSI, ISTE, IAENG and Indian National
            Congress. Working in the field of Wireless and Sensor Networks,
            Optimization Techniques
          </p> */}
          <div className="grid md:grid-cols-2 bg-bg p-3 rounded-">
            <div className="gap-3 flex flex-col justify-center">
              <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
              Dr. K. Nikitha
              </h1>
              <p className="text-justify p-3 text-white">
              B.Tech (Electronics and Communications Engg., JNTUH, 2003), M.
            Tech.(Information Technology, Andhra University, 2008), Ph.
            D.(Engg., GITAM University, Visakhapatnam, 2015) in the field of
            Wireless Sensor Networks. Worked as Associate Professor in
            Information Technology Department, MVSR Engineering College,
            Hyderabad (2017-19) two years. Formerly, as Assistant Professor in
            the Department of Computer Science and Engineering, GITAM University
            (2005-16). 16 years of teaching and research experiences. Qualified
            UGC-NET and TSET. Published about 15 papers in peer reviewed
            International Journals indexed in SCI, Scopus and about 10 in
            National and International Conferences. Organized various
            conferences and workshops including an International Conference on
            Wireless and Optical Communications Networks sponsored by IEEE. Life
            Member of professional bodies CSI, ISTE, IAENG and Indian National
            Congress. Working in the field of Wireless and Sensor Networks,
            Optimization Techniques
              </p>
            </div>
            <div className="p-3 flex flex-row justify-center">
              <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                <img src={img2} className="p-3 w-full h-full object-contain rounded-full" />
                <h1 className="text-wrap text-white">
                  Dr. K. Nikitha  
                </h1>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-2 bg-bg rounded p-3">
            <div className="gap-3 flex flex-col justify-center">
              <h1 className="text-blue-700 text-3xl font-bold pl-3 uppercase">
              Dr.S.Radharani
              </h1>
              <p className="text-justify text-white p-3">
              M.C.A(Master of Computer Applications from OU, 1997), M. Tech.(
            2012), Ph. D. in the field of security in cloud computing. Worked as
            Assistant Professor in Department of CSE,University College of
            Engineering, Osmania University Hyderabad (2098-2019) 21 years. 24
            years of teaching and research experiences. Qualified in APSET.
            Published about 5 papers in peer reviewed International Journals
            indexed in SCI, Scopus and in International Conferences. Organized
            various conferences and workshops including an International
            Conferences sponsored by IEEE. Life Member of professional bodies
            IETE. Working in the field of Security issues in Cloud Computing .
              </p>
            </div>
            <div className="p-3 flex flex-row justify-center">
              <div className="flex flex-col justify-center items-center rounded-md p-2 h-auto md:w-1/3">
                <img src={img1} className="p-3 w-60 h-60 object-contain rounded-full" />
                <h1 className="text-wrap text-white">
                Dr.S.Radharani
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cs;
